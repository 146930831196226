<mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
<mat-stepper [linear]="isLinear" #stepper animationDuration="0">
  <mat-step [stepControl]="firstFormGroup" label="Import pliku">
    <div mat-dialog-content>
      <h2>Import pliku</h2>
      <p class="p-content">
        {{ 'orders-front.add_file_with_settlements_xlsx' | translate }}
      </p>
      <div class="did-floating-label-content w-100 icon">
        <input
          class="did-floating-input"
          readonly
          [formControl]="display"
          type="text"
          placeholder=" "
          required />
        <label class="did-floating-label">{{
          'orders-front.add_attachment' | translate
        }}</label>
        <input
          type="file"
          multiple
          size="25000000"
          hidden
          #f_input
          (change)="handleFileInputChange(f_input?.files)" />

        <mat-error *ngIf="invalid_file">Brak pliku</mat-error>
        <mat-error *ngIf="error">{{ error.error || '' | translate }}</mat-error>
        <mat-icon (click)="f_input.click()"
          ><mat-icon>publish</mat-icon></mat-icon
        >
        <mat-error *ngIf="error">{{ error || '' | translate }}</mat-error>
      </div>
    </div>
    <div mat-dialog-actions class="right-button">
      <button
        mat-button
        class="secondary-btn"
        color="accent"
        [mat-dialog-close]="'cancel'"
        [disabled]="isLoading">
        {{ 'orders-front.cancel' | translate }}
      </button>
      <button
        mat-button
        class="primary-btn right-button"
        color="primary"
        (click)="addXlsx(stepper)"
        [disabled]="isLoading">
        {{ 'orders-front.next' | translate }}
      </button>
    </div>
  </mat-step>
  <mat-step [stepControl]="secondFormGroup" label="Podsumowanie weryfikacji">
    <div mat-dialog-content>
      <h2 *ngIf="XlsxIsWrong">Weryfikacja zakończona błędem</h2>
      <h2 *ngIf="!XlsxIsWrong">Weryfikacja zakończona prawidłowo</h2>
      <h4 *ngIf="XlsxIsWrong">
        Aby rozliczyć zlecenia wymagana jest poprawna weryfikacja wszystkich
        załączonych zleceń. Zapoznaj się z szczegółami błędów. Popraw błędy i
        spróbuj ponownie
      </h4>
      <hr />
      <div class="overflow">
        <table mat-table [dataSource]="resXlsx.verifiedOrders">
          <ng-container matColumnDef="orderFaultNumber">
            <th mat-header-cell *matHeaderCellDef>Nr. zlecenia</th>
            <td mat-cell *matCellDef="let element">
              {{ element.orderFaultNumber }}
            </td>
          </ng-container>
          <ng-container matColumnDef="invoiceNumber">
            <th mat-header-cell *matHeaderCellDef>Nr. faktury</th>
            <td mat-cell *matCellDef="let element">
              {{ element.invoiceNumber }}
            </td>
          </ng-container>
          <ng-container matColumnDef="amountFromFile">
            <th mat-header-cell *matHeaderCellDef>Kwota</th>
            <td mat-cell *matCellDef="let element">
              {{ element.amountFromFile }}
            </td>
          </ng-container>
          <ng-container matColumnDef="info">
            <th mat-header-cell *matHeaderCellDef>Opis</th>
            <td
              mat-cell
              *matCellDef="let element"
              [ngClass]="{ 'error': element?.isValid == false }">
              {{ element.info }}
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
      <div class="empty-table-view" *ngIf="resXlsx?.data?.length === 0">
        <p>{{ 'no_data' | translate }}</p>
      </div>
      <!-- <mat-list>
                <mat-list-item *ngFor="let verifiedFileXlsx of resXlsx.verifiedOrders">
                    <div matListItemTitle>Nr zlecenia : {{verifiedFileXlsx.orderFaultNumber
                        || ''}}</div>
                    <div matListItemLine> Nr faktury: {{verifiedFileXlsx.invoiceNumber || ''}} |
                        Kwota: {{verifiedFileXlsx.amountFromFile}} </div>
                    <div matListItemLine> Błąd: {{verifiedFileXlsx.info || ''}}</div>

                </mat-list-item>
            </mat-list> -->
      <hr />
    </div>
    <div mat-dialog-actions *ngIf="XlsxIsWrong" class="right-button">
      <button
        mat-button
        class="primary-btn right-button"
        [mat-dialog-close]="'succes'"
        color="primary"
        [disabled]="isLoading">
        {{ 'orders-front.close' | translate }}
      </button>
    </div>
    <div mat-dialog-actions class="right-button" *ngIf="!XlsxIsWrong">
      <button
        mat-button
        class="primary-btn right-button"
        color="primary"
        (click)="nextStep(stepper)">
        {{ 'orders-front.next' | translate }}
      </button>
    </div>
  </mat-step>
  <mat-step [stepControl]="thirdFormGroup" label="Faktura">
    <p class="title-desc">
      {{ 'orders-front.fill_in_the_information_below' | translate }}
    </p>
    <hr class="custom-hr" />
    <div mat-dialog-content>
      <div class="flex">
        <div class="did-floating-label-content mr-10 disabled">
          <input
            disabled
            class="did-floating-input disabled"
            type="text"
            placeholder=" "
            [(ngModel)]="invoice_number" />
          <label class="did-floating-label disabled">{{
            'orders-front.invoice_number' | translate
          }}</label>
        </div>
        <div class="did-floating-label-content disabled">
          <input
            disabled
            class="did-floating-input disabled"
            matInput
            [(ngModel)]="invoice_period" />

          <label class="did-floating-label disabled">{{
            'orders-front.invoice_period' | translate
          }}</label>
        </div>
      </div>
      <div class="flex">
        <div class="did-floating-label-content mr-10">
          <input
            disabled
            class="did-floating-input"
            type="text"
            placeholder="resXlsx"
            [ngModel]="
              net_invoice_amount | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
            " />
          <label class="did-floating-label">{{
            'orders-front.net_invoice_amount' | translate
          }}</label>
        </div>
        <div class="did-floating-label-content">
          <input
            matInput
            class="did-floating-input"
            [(ngModel)]="this.gross_invoice_amount"
            type="number"
            placeholder=" "
            required />

          <label class="did-floating-label">{{
            'orders-front.gross_invoice_amount' | translate
          }}</label>
        </div>
      </div>
      <p class="p-content">{{ 'orders-front.add_invoice' | translate }}</p>
      <div class="did-floating-label-content w-100 icon">
        <input
          class="did-floating-input"
          readonly
          [formControl]="displayInvoice"
          type="text"
          placeholder=" "
          required />
        <label class="did-floating-label">{{
          'orders-front.add_invoice' | translate
        }}</label>
        <input
          type="file"
          multiple
          size="25000000"
          hidden
          #invoice_input
          (change)="handleFileInputChangeInvoice(invoice_input?.files)" />

        <mat-error *ngIf="invalid_file">Brak pliku</mat-error>
        <mat-icon (click)="invoice_input.click()"
          ><mat-icon>publish</mat-icon></mat-icon
        >
        <mat-error *ngIf="error">{{ error.error || '' | translate }}</mat-error>
        <mat-error *ngIf="invalid"
          >Wartość brutto jest błędna i niezgodna z plikiem
          weryfikacyjnym</mat-error
        >
      </div>
    </div>
    <div mat-dialog-actions class="right-button">
      <button
        mat-button
        class="primary-btn right-button"
        color="primary"
        (click)="addInvoice(stepper)"
        [disabled]="isLoading">
        {{ 'orders-front.next' | translate }}
      </button>
      <button
        mat-button
        class="secondary-btn right-button"
        color="accent"
        [mat-dialog-close]="'cancel'"
        [disabled]="isLoading">
        {{ 'orders-front.cancel' | translate }}
      </button>
    </div>
  </mat-step>
  <mat-step [stepControl]="fourthFormGroup" label="Podsumowanie">
    <h1 class="success">Succes</h1>
    <h3 class="success">Rozliczenie zakończone sukcesem</h3>
    <div mat-dialog-actions class="right-button">
      <button
        mat-button
        class="primary-btn right-button"
        [mat-dialog-close]="'succes'"
        color="primary"
        [disabled]="isLoading">
        {{ 'orders-front.close' | translate }}
      </button>
    </div>
  </mat-step>
</mat-stepper>
